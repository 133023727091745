@font-face {
font-family: 'arabic';
src: url(/_next/static/media/34cf76f87cb20ae7-s.p.otf) format('opentype');
font-display: swap;
font-style: normal;
}@font-face {font-family: 'arabic Fallback';src: local("Arial");ascent-override: 110.69%;descent-override: 50.64%;line-gap-override: 0.00%;size-adjust: 67.49%
}.__className_00ad19 {font-family: 'arabic', 'arabic Fallback';font-style: normal
}.__variable_00ad19 {--font-arabic: 'arabic', 'arabic Fallback'
}

@font-face {
font-family: 'secondary';
src: url(/_next/static/media/b55d59a84cc866b9-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: light;
}

@font-face {
font-family: 'secondary';
src: url(/_next/static/media/a3190672d0530f5c-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'secondary';
src: url(/_next/static/media/ff8e8acab019dfa0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: 'secondary';
src: url(/_next/static/media/cfb7c962df66cb59-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: semiBold;
}

@font-face {
font-family: 'secondary';
src: url(/_next/static/media/9610010d43345f7e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: bold;
}@font-face {font-family: 'secondary Fallback';src: local("Arial");ascent-override: 85.76%;descent-override: 21.44%;line-gap-override: 17.87%;size-adjust: 111.94%
}.__className_3f3ff4 {font-family: 'secondary', 'secondary Fallback'
}.__variable_3f3ff4 {--font-secondary: 'secondary', 'secondary Fallback'
}

@font-face {
font-family: 'main';
src: url(/_next/static/media/7a4c2ec59ba153f9-s.p.otf) format('opentype');
font-display: swap;
font-style: normal;
}@font-face {font-family: 'main Fallback';src: local("Arial");ascent-override: 64.08%;descent-override: 19.88%;line-gap-override: 7.95%;size-adjust: 125.78%
}.__className_d3e73d {font-family: 'main', 'main Fallback';font-style: normal
}.__variable_d3e73d {--font-main: 'main', 'main Fallback'
}

@font-face {
font-family: 'bentogaThin';
src: url(/_next/static/media/33605be5dda1972f-s.p.otf) format('opentype');
font-display: swap;
font-style: thin;
}@font-face {font-family: 'bentogaThin Fallback';src: local("Arial");ascent-override: 78.17%;descent-override: 23.49%;line-gap-override: 9.40%;size-adjust: 106.43%
}.__className_e02412 {font-family: 'bentogaThin', 'bentogaThin Fallback';font-style: thin
}.__variable_e02412 {--font-bentoga: 'bentogaThin', 'bentogaThin Fallback'
}

